@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200;275;300;400;500;600;700&display=swap");
@tailwind base;

/* Firefox */
.custom_scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #e0e7ef;
}

/* Chrome, Edge, and Safari */
.custom_scrollbar::-webkit-scrollbar {
  width: 5px;
}

.css-1h38hvx-MuiFormControl-root-MuiTextField-root, .css-1qqsdnr-MuiAutocomplete-root{
  background-color: white !important;
}

.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 13px 14px !important;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 13px 14px !important;
  background-color: white !important;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input:hover{
  outline: transparent !important;
}

.custom_scrollbar::-webkit-scrollbar-track {
  background: inherit;
  border-radius: 5px;
}

.custom_scrollbar::-webkit-scrollbar-thumb {
  background-color: inherit;
  border-radius: 14px;
  border: 3px solid #e0e7ef;
}

@tailwind components;
@tailwind utilities;

@layer components {

  .titleAudioManagement {
    @apply text-heading-6 font-medium text-ct-blue-95 leading-6;
  }

  .nameParagraph1 {
    @apply text-small font-medium text-ct-blue-95;
  }

  .titleParagraph {
    @apply text-small font-medium text-ct-blue-90;
  }

  .titleParagraphMedium {
    @apply text-[18px] font-medium text-ct-blue-95;
  }

  .deleteIcon {
    @apply w-[20px] h-[20px];
  }

  .sideDrawerActiveNav {
    @apply bg-blue-10 text-secondary-blue-50 font-semibold rounded-[20px]  flex justify-between items-center;
  }

  .sideDrawerDeactiveNav {
    @apply text-blue-gray-A50 rounded-[20px] flex justify-between items-center;
  }

  .comboBoxLabel {
    @apply font-medium text-blue-gray-75 text-small;
  }

  .xSmallButton {
    @apply text-xxs font-medium py-2 px-7 rounded-md;
  }

  .smallButton {
    @apply text-small font-medium py-2 px-8 rounded-md;
  }

  .mediumButton {
    @apply text-base font-medium py-[10px] px-8 rounded-md;
  }

  .xSmallButtonTertiary {
    @apply text-xxs font-medium py-2 px-4 rounded-md;
  }

  .smallButtonTertiary {
    @apply text-small font-medium py-2.5 px-4 rounded-md;
  }

  .mediumButtonTertiary {
    @apply text-base font-medium py-3 px-6 rounded-md;
  }

  .xSmallIconButton {
    @apply text-xxs font-medium py-2 rounded-md;
  }

  .smallIconButton {
    @apply text-small font-medium py-2 rounded-md;
  }

  .mediumIconButton {
    @apply text-base font-medium py-2.5 rounded-md;
  }

  .activeColor {
    @apply bg-blue-10 hover:bg-blue-20 active:bg-blue-30;
  }

  .deactiveColor {
    @apply hover:bg-ct-blue-05 active:bg-ct-blue-10;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Fira Sans", sans-serif;
}

code {
  font-family: "Fira Sans", sans-serif;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  font-family: "Fira Sans", sans-serif;
}

#triangle {
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
}

/* for globally stop this  */
.recharts-layer .recharts-pie-sector {
  outline: none;
}

.recharts-layer .recharts-pie {
  outline: none;
}
.recharts-layer {
  outline: none;
}

.lable-text:hover
  .recharts-tooltip-wrapper
  .recharts-tooltip-wrapper-left
  .recharts-tooltip-wrapper-top {
  visibility: visible !important;
}

/* hide scrollbar but allow scrolling */
/* .selected-items {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
}

.selected-items::-webkit-scrollbar {
  display: none;
} */

.selected-items {
  display: inline-block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #2c79be;
}

.script-modal-description {
  display: -webkit-box;
  width: 752px;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.custom-scrollBar-width-8::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollBar-height-5::-webkit-scrollbar {
  height: 8px;
}

/* Track */
/* .custom-scrollBar::-webkit-scrollbar-track ,
.custom-scrollBar2::-webkit-scrollbar-track{
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
} */
 
/* Handle */
.custom-scrollBar::-webkit-scrollbar-thumb {
  background-color: #E0E7EF;
  border-radius: 10px;
}

/* Handle on hover */
/* .custom-scrollBar::-webkit-scrollbar-thumb:hover {
  background: darkgray; 
} */

/* Music WaveForm */
#waveform wave {
  cursor: pointer !important;
}

.Toastify__toast{
  min-height: auto !important;
  min-width: auto !important;
}

.Toastify__toast-container{
  width: auto !important;
}
.focused .MuiOutlinedInput-notchedOutline {
  border-color: blue; /* Change the outline color here */
}

.hidden-label label {
  display: none !important;
}

